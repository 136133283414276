function checkColumns() {
  var toggles = $('.hide-column');

  $.each(toggles, function(i, toggle) {
    var col = toggle.getAttribute('id');
    var storageKey = col + '_hidden';

    th = $("th[data-column='" + col +"']");
    td = $("td[data-column='" + col +"']");
    checkbox = $("input#" + col + "[type='checkbox']");

    if(localStorage.getItem(storageKey) != 0 && localStorage.getItem(storageKey) != 1 && checkbox.data('checked-initially') != '1') {
      th.hide();
      td.hide();
      checkbox.prop('checked', false);
    } else if(localStorage.getItem(storageKey) == 1) {
      th.hide();
      td.hide();
      checkbox.prop('checked', false);
    } else {
      th.show();
      td.show();
      checkbox.prop('checked', true);
    }
  });  
};

$(document).on('change', '.hide-column', function(e) {
  var storageKey = $(this).attr('id') + '_hidden';
  
  if(!this.checked && (localStorage.getItem(storageKey) || 0) == 0) {
    localStorage.setItem(storageKey, 1);
  } else {
    localStorage.setItem(storageKey, 0);
  }

  checkColumns();
});

$(document).on('change', 'select#order, select#category_id', function(e) {
  e.preventDefault();
  $('#table_form').submit();
});

$(document).on('turbolinks:load', function() {
  checkColumns();
});
